import React, { useEffect, useContext } from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { BUTTON_STYLE, COLOR, PADDING } from '@latitude/core/utils/constants';
import Accordion from '@/components/Accordion/Accordion';
import { Box } from '@latitude/box';
import { BrandedCallout } from '@latitude/callout';
import { Link } from '@latitude/link';

import ManageYourLoan from '@/components/ManageYourLoan/ManageYourLoan';
import Layout from '../../components/layout';
import Metadata from '../../components/Metadata/Metadata';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';

import LifeAtLatitude from './_life-at-latitude';
import OurStory from './_our-story';
import OurValues from './_our-values';
import WhyLatitude from './_why-latitude';
import PageData from './data/careers.json';
import heroImage from '../../images/hero/careers.webp';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

const Careers = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY5};
        `}
      >
        <Metadata
          title={PageData.meta.title}
          description={PageData.meta.description}
          canonical="/careers/"
        />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={heroImage}
            title="Let's Work Together"
            subTitle={
              <>
                We’ve set our sights on helping people from all walks of life to
                shop and live better. So, if you’re someone who wants to do
                amazing work and has a strong desire to make a difference, we’d
                love to chat.
              </>
            }
          />
        )}
        <StickyNavigationBranded
          ctaHref="https://latitudefinancial.wd3.myworkdayjobs.com/careers"
          ctaText="Join the team"
          ctaType={BUTTON_STYLE.SECONDARY}
          offsetElem="[data-sticky-navigation-offset]"
          items={PageData.stickyNavigation}
          trackEventData={{
            action: 'careers-link',
            category: 'cta'
          }}
          trackId="careers-stickynav"
        />

        <Anchor id="why-latitude" />
        <WhyLatitude />
        <Divider />
        <OurValues />
        <Divider />
        <OurStory />
        <Anchor id="life-at-latitude" />
        <LifeAtLatitude />

        <AnalyticsLocationProvider location="Our benefits">
          <ManageYourLoan heading="Our benefits">
            <Accordion
              titleBgColor="grey-light"
              items={PageData.content.accordionData.map(accordionDataItem => {
                return {
                  id: accordionDataItem.id,
                  title: accordionDataItem.title,
                  content: (
                    <Box padding={PADDING.P16}>
                      {ReactHtmlParser(accordionDataItem.data)}
                    </Box>
                  )
                };
              })}
            />
          </ManageYourLoan>
        </AnalyticsLocationProvider>

        <Anchor id="find-a-career" />
        <BrandedCallout
          hasConfettiBackground
          moreVerticalPadding
          heading="Find a career"
          line1="Find a new career – start the search now."
          cta={
            <Link
              href="https://latitudefinancial.wd3.myworkdayjobs.com/careers"
              variant="secondary"
              css="margin-top: 16px;"
              button={BUTTON_STYLE.SECONDARY}
              data-trackid="callout-find-a-career"
              trackEventData={{
                action: 'careers-link',
                category: 'cta',
                location: 'Find a career'
              }}
            >
              Join the team
            </Link>
          }
        />
      </main>
    </Layout>
  );
};

export default Careers;

const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
`;

const Divider = () => {
  return (
    <hr
      css={`
        width: 80%;
        height: 1px;
        margin: 1px auto;
        background-color: ${COLOR.GREY16};
        color: ${COLOR.GREY16};
        border: 0 none;
      `}
    />
  );
};
